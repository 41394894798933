import {FC, ReactElement} from "react";
import {Link} from "react-router-dom";

import style from "./icons.module.css";

export const Loadup: FC = () : ReactElement => {

  return (
    <Link to="/loadup">
      <img src="/image/loadup.png" alt="Packen" className={style.icon}/>
    </Link>
  )

}