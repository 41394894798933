import {FC, ReactElement} from "react";

import {Logo} from "../../icons/Logo";
import {Headline} from "../../components/Headline";
import {Login} from "../../icons/Login";

import style from "./home.module.css";

export const HomeHeader: FC = () : ReactElement => {

  return (
    <div className={style.header}>
      <Logo />
      <Headline headline={"Werkzeugliste"} />
      <Login />
    </div>
  )

}
