import {FC, ReactElement} from "react";

import stylesTool from './tool.module.css';

interface ToolProps {
  name: String;
}

export const Tool: FC<ToolProps> = (props): ReactElement => {

  return (
    <div className={stylesTool.tool}>
      <img src="/image/Wuerth-Akkuschrauber.jpeg" alt="Akkuschrauber"/>
      <div className={stylesTool.text}>
        {props.name}
      </div>
    </div>
  )

}
