// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/app/font/GOTHIC.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  border: 0;
  margin: 0;
  background-color: #808080;
}

@font-face {
  font-family: 'Gothic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

h2, h3, h4 {
  font-family: Gothic;
  font-weight: bold;
  color: var(--text-color);
  padding-left: 15px;
  margin: auto 0;
  background-color: var(--secondary-color);
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 19px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,+DAAuD;AACzD;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  border: 0;\n  margin: 0;\n  background-color: #808080;\n}\n\n@font-face {\n  font-family: 'Gothic';\n  src: url(\"/src/app/font/GOTHIC.TTF\") format('truetype');\n}\n\nh2, h3, h4 {\n  font-family: Gothic;\n  font-weight: bold;\n  color: var(--text-color);\n  padding-left: 15px;\n  margin: auto 0;\n  background-color: var(--secondary-color);\n}\n\nh2 {\n  font-size: 26px;\n}\n\nh3 {\n  font-size: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
