import {FC, ReactElement, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {HomeOutlet} from "./app/view/home/HomeOutlet";
import {ListOutlet} from "./app/view/list/ListOutlet";
import {LoadupOutlet} from "./app/view/loadup/LoadupOutlet";
import {StoreOutlet} from "./app/view/store/StoreOutlet";
import {ToolOutlet} from "./app/view/tool/ToolOutlet";
import {ProjectList} from "./app/view/list/projectlist/ProjectList";
import {NewProjectList} from "./app/view/list/projectlist/NewProjectList";

export const App: FC = (): ReactElement => {

  const [menuShow, setMenuShow] = useState<boolean>(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeOutlet menuShow={menuShow} setMenuShow={setMenuShow} />} />
        <Route path="/list" element={<ListOutlet menuShow={menuShow} setMenuShow={setMenuShow} />}>
          <Route path="/list" element={<ProjectList />} />
          <Route path="/list/newprojectlist" element={<NewProjectList />} />
        </Route>
        <Route path="/loadup" element={<LoadupOutlet menuShow={menuShow} setMenuShow={setMenuShow} />} />
        <Route path="/store" element={<StoreOutlet menuShow={menuShow} setMenuShow={setMenuShow} />} />
        <Route path="/tool" element={<ToolOutlet menuShow={menuShow} setMenuShow={setMenuShow} />} />
      </Routes>
    </BrowserRouter>
  )

}