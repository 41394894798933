import {FC, ReactElement} from "react";

import style from "../icons.module.css";

export const QrCode: FC = () : ReactElement => {

  return (
    <img src="/image/qr-code-scan-icon.png" alt="QrCode" className={style.icon}/>
  )

}