import {FC, ReactElement} from "react";

import style from "./components.module.css";

export const Search: FC = () : ReactElement => {

  return (
    <input className={`${style.components} ${style.search}`} />
  )

}
