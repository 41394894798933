import {FC, ReactElement} from "react";

import {Logo} from "../../icons/Logo";
import {Headline} from "../../components/Headline";
import {QrCode} from "../../icons/qrcode/QrCode";
import {List} from "../../icons/List";

import style from "./loadup.module.css";

export const LoadupHeader: FC = () : ReactElement => {

  return (
    <div className={style.header}>
      <Logo />
      <Headline headline={"Packen"} />
      <QrCode />
      <List />
    </div>
  )

}
