// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components_components__HqWO6 {
  display: grid;
  place-items: center;
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  box-sizing: border-box;

  background-color: var(--third-color);
  border: var(--border-size) var(--third-color) outset;
  border-radius: 5px;
  cursor: pointer;
}

.components_components__HqWO6:hover {
  border: var(--border-size) var(--third-color) inset;
}

.components_search__MHJlo {
  margin: 0;
  width: auto;

  border: 2px var(--third-color) solid;
  background-color: var(--text-color);
}

.components_search__MHJlo:hover {
  border: 2px var(--third-color) solid;
}

.components_headline__yOdKq {
  padding: 0;
  margin: auto;
  color: var(--text-color);
  font-family: Gothic;
  font-size: 26px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/components.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,wBAAwB;EACxB,sBAAsB;;EAEtB,oCAAoC;EACpC,oDAAoD;EACpD,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,SAAS;EACT,WAAW;;EAEX,oCAAoC;EACpC,mCAAmC;AACrC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,wBAAwB;EACxB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".components {\n  display: grid;\n  place-items: center;\n  position: relative;\n  width: var(--icon-size);\n  height: var(--icon-size);\n  box-sizing: border-box;\n\n  background-color: var(--third-color);\n  border: var(--border-size) var(--third-color) outset;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.components:hover {\n  border: var(--border-size) var(--third-color) inset;\n}\n\n.search {\n  margin: 0;\n  width: auto;\n\n  border: 2px var(--third-color) solid;\n  background-color: var(--text-color);\n}\n\n.search:hover {\n  border: 2px var(--third-color) solid;\n}\n\n.headline {\n  padding: 0;\n  margin: auto;\n  color: var(--text-color);\n  font-family: Gothic;\n  font-size: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"components": `components_components__HqWO6`,
	"search": `components_search__MHJlo`,
	"headline": `components_headline__yOdKq`
};
export default ___CSS_LOADER_EXPORT___;
