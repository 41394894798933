// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_list__Gz\\+7A {
  display: grid;
  grid-template-columns: var(--icon-size) auto;
  margin: 8px 8px 0 8px;
  padding: 10px;
  background-color: var(--secondary-color);
  border: var(--border-size) var(--secondary-color) outset;
  border-radius: 12px;
  text-decoration: none;
}

.button_list__Gz\\+7A:hover {
  border: var(--border-size) var(--secondary-color) inset;
}

.button_list__Gz\\+7A > * {
  text-decoration: none;
}

.button_listText__kq9X4 {
  color: var(--text-color);
  padding-left: 15px;
  font-size: 17px;
}

.button_listTextSingle__JKcKp {
  color: var(--text-color);
  padding-left: 15px;
  font-size: 17px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/button/button.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4CAA4C;EAC5C,qBAAqB;EACrB,aAAa;EACb,wCAAwC;EACxC,wDAAwD;EACxD,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".list {\n  display: grid;\n  grid-template-columns: var(--icon-size) auto;\n  margin: 8px 8px 0 8px;\n  padding: 10px;\n  background-color: var(--secondary-color);\n  border: var(--border-size) var(--secondary-color) outset;\n  border-radius: 12px;\n  text-decoration: none;\n}\n\n.list:hover {\n  border: var(--border-size) var(--secondary-color) inset;\n}\n\n.list > * {\n  text-decoration: none;\n}\n\n.listText {\n  color: var(--text-color);\n  padding-left: 15px;\n  font-size: 17px;\n}\n\n.listTextSingle {\n  color: var(--text-color);\n  padding-left: 15px;\n  font-size: 17px;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `button_list__Gz+7A`,
	"listText": `button_listText__kq9X4`,
	"listTextSingle": `button_listTextSingle__JKcKp`
};
export default ___CSS_LOADER_EXPORT___;
