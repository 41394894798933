import {FC, ReactElement} from "react";
import {Link} from "react-router-dom";

import style from "./icons.module.css";

interface props {
  url: String
}

export const Plus: FC<props> = (props) : ReactElement => {

  return (
    <Link to={`${props.url}`}>
      <div className={style.icon}>
        <div className={style.plusMinus} />
        <div className={style.plusPlus} />
      </div>
    </Link>
  )

}