import {FC, ReactElement} from "react";
import {HomeHeader} from "./HomeHeader";
import {HomeMain} from "./HomeMain";

interface props {
  menuShow: boolean;
  setMenuShow: Function;
}

export const HomeOutlet: FC<props> = (props) : ReactElement => {

  return (
    <div>
      <HomeHeader />

      <HomeMain />
    </div>
  )

}