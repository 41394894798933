import {FC, ReactElement} from "react";

import style from "./icons.module.css";

interface props {
  menuShow: boolean
  setMenuShow: Function
}

export const Menu: FC<props> = (props): ReactElement => {

  const handleClick = () => {
    props.setMenuShow(!props.menuShow);
  }

  return (
    <div className={style.icon} onClick={handleClick}>
      {props.menuShow
        ? <div>
            <div className={style.menuExtents}></div>
          </div>
        : <div>
            <div className={style.menuMinus}></div>
            <div className={style.menuMinusBackgroud}></div>
            <div className={style.menuMinus}></div>
            <div className={style.menuMinusBackgroud}></div>
            <div className={style.menuMinus}></div>
        </div>
      }
    </div>
  )

}