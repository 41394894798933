// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_header__THVdI {
  display: grid;
  grid-template-columns: var(--icon-size) auto var(--icon-size);
  grid-template-rows: var(--icon-size);
  grid-gap: var(--grid-gap);
  background-color: var(--secondary-color);
  padding: 10px;
}

.home_link__89T\\+G {
  display: grid;
  grid-template-columns: var(--icon-size) auto;
  margin: 8px;
  padding: 10px;
  background-color: var(--secondary-color);
  border: var(--border-size) var(--secondary-color) outset;
  border-radius: 12px;
  text-decoration: none;
}

.home_link__89T\\+G:hover {
  border: var(--border-size) var(--secondary-color) inset;
}

.home_link__89T\\+G > * {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/view/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6DAA6D;EAC7D,oCAAoC;EACpC,yBAAyB;EACzB,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,aAAa;EACb,4CAA4C;EAC5C,WAAW;EACX,aAAa;EACb,wCAAwC;EACxC,wDAAwD;EACxD,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".header {\n  display: grid;\n  grid-template-columns: var(--icon-size) auto var(--icon-size);\n  grid-template-rows: var(--icon-size);\n  grid-gap: var(--grid-gap);\n  background-color: var(--secondary-color);\n  padding: 10px;\n}\n\n.link {\n  display: grid;\n  grid-template-columns: var(--icon-size) auto;\n  margin: 8px;\n  padding: 10px;\n  background-color: var(--secondary-color);\n  border: var(--border-size) var(--secondary-color) outset;\n  border-radius: 12px;\n  text-decoration: none;\n}\n\n.link:hover {\n  border: var(--border-size) var(--secondary-color) inset;\n}\n\n.link > * {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `home_header__THVdI`,
	"link": `home_link__89T+G`
};
export default ___CSS_LOADER_EXPORT___;
