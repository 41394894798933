// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #808080;
  --secondary-color: #293241;
  --third-color: #FF00FF;
  --modal-color: #F1F1F1;
  --text-color: #FFFFFF;

  --icon-size: 40px;
  --border-size: 5px;
  --grid-gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;;EAErB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[":root {\n  --primary-color: #808080;\n  --secondary-color: #293241;\n  --third-color: #FF00FF;\n  --modal-color: #F1F1F1;\n  --text-color: #FFFFFF;\n\n  --icon-size: 40px;\n  --border-size: 5px;\n  --grid-gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
