import {FC, ReactElement, useEffect, useState} from "react";
import axios from "axios";

import {Tool} from "./Tool";

import stylesTool from './tool.module.css';
import {useSelector} from "react-redux";
import {ToolSelected} from "./ToolSelected";

interface DataType {
  uuid: string;
  name: string;
}

export const ToolContent: FC = (): ReactElement => {
  const [data, setData] = useState<DataType[]>([]);
  const [selected, setSelected] = useState<string | null>(null);

  const api = useSelector((state: any) => state.api);
  const url = api + "/tool"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error('Es gab ein Problem beim Abrufen der Daten:', error);
      }
    }
    fetchData();
  }, [url]);

  const handleSelected = (uuid: string) => {
    if (selected === uuid) {
      setSelected(null);
    } else {
      setSelected(uuid);
    }
  }

  return (
    <div className={stylesTool.content}>
      {data.map((item, index) => (
        item.uuid === selected
          ?
          <div className={stylesTool.toolselectedgrid} onClick={() => handleSelected(item.uuid)}>
            <ToolSelected
              key={index}
              uuid={item.uuid}
              name={item.name}
            />
          </div>
          :
          <div className="toolgrid" onClick={() => handleSelected(item.uuid)}>
            <Tool key={index} name={item.name}/>
          </div>
      ))}
    </div>
  )
}