import {FC, ReactElement} from "react";
import {Link} from "react-router-dom";

import {List} from "../../icons/List";
import {Loadup} from "../../icons/Loadup";
import {Store} from "../../icons/Store";
import {Tool} from "../../icons/Tool";

import style from './home.module.css';

export const HomeMain: FC = () : ReactElement => {

  return (
    <div>
      <Link to="/list" style={{ textDecoration: 'none' }}>
        <div className={style.link}>
          <List />
          <h3>Listen</h3>
        </div>
      </Link>
      <Link to="/loadup" style={{ textDecoration: 'none' }}>
        <div className={style.link}>
          <Loadup />
          <h3>Packen</h3>
        </div>
      </Link>
      <Link to="/store" style={{ textDecoration: 'none' }}>
        <div className={style.link}>
          <Store />
          <h3>Lagern</h3>
        </div>
      </Link>
      <Link to="/tool" style={{ textDecoration: 'none' }}>
        <div className={style.link}>
          <Tool />
          <h3>Werkzeuge</h3>
        </div>
      </Link>
    </div>
  )

}