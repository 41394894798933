import {FC, ReactElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";

import {ButtonWidth1Icon} from "../../../components/button/ButtonWidth1Icon";
import {Project} from "../../../icons/Project";
import {Close} from "../../../icons/Close";

interface project {
  uuid: string;
  name: string;
  customer: {
    name: string;
  }
}

interface props {
  selectedProject: project | undefined;
  setSelectedProject: Function;
  modal: boolean;
  setModal: Function;
}

export const ProjectModal: FC<props> = (props): ReactElement => {

  const api = useSelector((state: any) => state.api);
  const url = api + "/project";

  const [data, setData] = useState<project[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url + "/getunfinished");
        setData(response.data);
      } catch (error) {
        console.error('Es gab ein Problem beim Abrufen der Daten:', error);
      }
    }
    fetchData();
  }, [url]);

  const toggleModal = () => {
    props.setModal(!props.modal);
  }

  if (props.modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  const selectProject = (item: project) => {
    console.log(item.name);
    props.setSelectedProject(item);
    props.setModal(false);
  }

  return (
    <div>
      {props.modal && (
        <div className="modal">
          <div className="overlay" onClick={toggleModal}></div>
          <div className="modal-content">
            <h3 className="header-modal">Projekt auswählen</h3>
            <div className="close-modal">
              <Close closeModal={props.setModal}/>
            </div>
            {data.map((item, index) => (
              <div key={index} onClick={() => selectProject(item)}>
                <ButtonWidth1Icon
                  item={{uuid: item.uuid, line1: item.customer.name, line2: item.name}}
                  icon={<Project/>}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
