import {FC, ReactElement} from "react";

import {Logo} from "../../icons/Logo";
import {Headline} from "../../components/Headline";
import {QrCode} from "../../icons/qrcode/QrCode";
import {List} from "../../icons/List";

import style from "./store.module.css";

interface props {
  menuShow: boolean
  setMenuShow: Function
}

export const StoreHeader: FC<props> = (props) : ReactElement => {

  return (
    <div className={style.header}>
      <Logo />
      <Headline headline={"Lagern"} />
      <QrCode />
      <List />
    </div>
  )

}
