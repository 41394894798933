// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store_header__2BxN- {
  display: grid;
  grid-template-columns: var(--icon-size) auto var(--icon-size) var(--icon-size);
  grid-template-rows: var(--icon-size);
  grid-gap: var(--grid-gap);
  background-color: var(--secondary-color);
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/view/store/store.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8EAA8E;EAC9E,oCAAoC;EACpC,yBAAyB;EACzB,wCAAwC;EACxC,YAAY;AACd","sourcesContent":[".header {\n  display: grid;\n  grid-template-columns: var(--icon-size) auto var(--icon-size) var(--icon-size);\n  grid-template-rows: var(--icon-size);\n  grid-gap: var(--grid-gap);\n  background-color: var(--secondary-color);\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `store_header__2BxN-`
};
export default ___CSS_LOADER_EXPORT___;
