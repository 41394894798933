import {FC, ReactElement} from "react";

import {ListHeader} from "./ListHeader";
import {ListMenulist} from "./ListMenulist";
import {Outlet} from "react-router-dom";

interface props {
  menuShow: boolean;
  setMenuShow: Function;
}

export const ListOutlet: FC<props> = (props) : ReactElement => {

  return (
    <div>
      <ListHeader menuShow={props.menuShow} setMenuShow={props.setMenuShow} />

      {props.menuShow ? <ListMenulist /> : ''}

      {<Outlet />}
    </div>
  )

}