import {FC, ReactElement} from "react";

import {Logo} from "../../icons/Logo";
import {Menu} from "../../icons/Menu";
import {QrCode} from "../../icons/qrcode/QrCode";
import {Headline} from "../../components/Headline";

import style from "./tool.module.css"

interface props {
  menuShow: boolean
  setMenuShow: Function
}

export const ToolHeader: FC<props> = (props) : ReactElement => {

  return (
    <div className={style.header}>
      <Logo />
      <Headline headline={"Werkzeuge"} />
      <QrCode />
      <Menu
        menuShow={props.menuShow}
        setMenuShow={props.setMenuShow}
      />
    </div>
  )

}
