import {FC, ReactElement} from "react";

import style from "./icons.module.css";

export const Login: FC = () : ReactElement => {

  return (
    <img src="/image/Thomas.jpg" alt="Thomas" className={style.icon} />
  )

}