import {FC, ReactElement} from "react";
import {LoadupHeader} from "./LoadupHeader";

interface props {
  menuShow: boolean;
  setMenuShow: Function;
}

export const LoadupOutlet: FC<props> = (props) : ReactElement => {

  return (
    <div>
      <LoadupHeader />

      <div className="layoutMain">Loadup Main</div>
    </div>
  )

}