import {FC, ReactElement} from "react";
import {Link} from "react-router-dom";

import style from "./icons.module.css";

export const List: FC = () : ReactElement => {

  return (
    <Link to="/list">
      <img src="/image/to-do-list.png" alt="Listen" className={style.icon}/>
    </Link>
  )

}