import {FC, ReactElement} from "react";
import {ToolHeader} from "./ToolHeader";
import {ToolMenulist} from "./ToolMenulist";
import {ToolContent} from "./ToolContent";

interface props {
  menuShow: boolean;
  setMenuShow: Function;
}

export const ToolOutlet: FC<props> = (props) : ReactElement => {

  return (
    <div>
      <ToolHeader menuShow={props.menuShow} setMenuShow={props.setMenuShow} />

      {props.menuShow ? <ToolMenulist /> : ''}

      <ToolContent />

    </div>
  )

}