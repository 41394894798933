import {FC, ReactElement} from "react";

import style from "./icons.module.css";

export const Project: FC = () : ReactElement => {

  return (
    <img src="/image/Projekt.png" alt="Projekt" className={style.icon} />
  )

}