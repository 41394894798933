import {FC, ReactElement} from "react";
import {StoreHeader} from "./StoreHeader";

interface props {
  menuShow: boolean;
  setMenuShow: Function;
}

export const StoreOutlet: FC<props> = (props) : ReactElement => {

  return (
    <div>
      <StoreHeader menuShow={props.menuShow} setMenuShow={props.setMenuShow}/>

      <div className="layoutMain">Store Main</div>
    </div>
  )

}