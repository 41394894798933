import {FC, ReactElement} from "react";
// @ts-ignore
import DatePicker from "react-datepicker";
import {de} from "date-fns/locale";

import {Close} from "../../icons/Close";

import 'react-datepicker/dist/react-datepicker.css';
import "./datepickermodal.css";

interface props {
  date: Date | null;
  setDate: Function;
  modal: boolean;
  setModal: Function;
}

export const DatePickerModal: FC<props> = (props): ReactElement => {

  const toggleModal = () => {
    props.setModal(!props.modal);
  }

  if (props.modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  const selectDate = (date: Date) => {
    const year = date.getFullYear();
    const MM = date.getMonth() + 1;
    const dd = date.getDate();

    const month = MM < 10 ? `0${MM}` : MM;
    const day = dd < 10 ? `0${dd}` : dd;

    const formatted = `${year}-${month}-${day}`;

    props.setDate(formatted);
    props.setModal(false);
  }

  return (
    <div>
      {props.modal && (
        <div className="modal">
          <div className="overlay" onClick={toggleModal}></div>
          <div className="modal-content">
            <h3 className="header-modal">Datum auswählen</h3>
            <div className="close-modal">
              <Close closeModal={props.setModal}/>
            </div>
            <DatePicker
              selected={props.date}
              onChange={(date: Date) => selectDate(date)}
              dateFormat="yyyy-MM-dd"
              locale={de}
              inline
            />
          </div>
        </div>
      )}
    </div>
  );
}
