import {FC, ReactElement} from "react";

import {Search} from "../../components/Search";
import {Login} from "../../icons/Login";

import style from "./list.module.css";

export const ListMenulist: FC = () : ReactElement => {

  return (
    <div className={style.menulist}>
      <Search />
      <Login />
    </div>
  )

}
