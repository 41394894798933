import {FC, ReactElement} from "react";

import {Logo} from "../../icons/Logo";
import {Menu} from "../../icons/Menu";
import {Headline} from "../../components/Headline";

import style from "./list.module.css";
import {Plus} from "../../icons/Plus";

interface props {
  menuShow: boolean
  setMenuShow: Function
}

export const ListHeader: FC<props> = (props) : ReactElement => {

  return (
    <div className={style.header}>
      <Logo />
      <Headline headline={"Listen"} />
      <Plus url="/list/newprojectlist" />
      <Menu
        menuShow={props.menuShow}
        setMenuShow={props.setMenuShow}
      />
    </div>
  )

}
