import {FC, ReactElement} from "react";

import style from "./icons.module.css";

interface props {
  closeModal: Function
}

export const Close: FC<props> = (props): ReactElement => {

  const handleClick = () => {
    props.closeModal(false);
  }

  return (
    <div className={style.icon} onClick={handleClick}>
      <div className={style.closebar}/>
      <div className={style.closebar}/>
    </div>
  )

}