import {FC, ReactElement} from "react";

import {Link} from "react-router-dom";

import style from "./icons.module.css";

export const Logo: FC = () : ReactElement => {

  return (
    <Link to="/">
      <img src="/image/favicon.jpg" alt="DesignSchnitt" className={style.logo}/>
    </Link>
  )

}