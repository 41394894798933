import {FC, ReactElement, ReactNode} from "react";

import style from "./button.module.css";

interface props {
  item: {
    uuid: string;
    line1: string;
    line2?: string | null;
  };
  icon: ReactNode;
}

export const ButtonWidth1Icon: FC<props> = (props): ReactElement => {

  return (
    <div key={props.item.uuid} className={style.list}>
      {props.icon}
      {props.item.line2
        ?
        <div className={style.listText}>
          <div>{props.item.line1}</div>
          <div>{props.item.line2}</div>
        </div>
        :
        <div className={style.listTextSingle}>
          <div>{props.item.line1}</div>
        </div>
      }

    </div>
  )

}