// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list_header__cnuNx,
.list_menulist__MY-U- {
  display: grid;
  grid-template-rows: var(--icon-size);
  grid-gap: var(--grid-gap);
  background-color: var(--secondary-color);
}

.list_header__cnuNx {
  grid-template-columns: var(--icon-size) auto var(--icon-size) var(--icon-size);
  padding: 10px;
}

.list_menulist__MY-U- {
  grid-template-columns: auto var(--icon-size);
  padding: 0 10px 5px;
}

.list_h3__gE6Hv {
  padding-bottom: 5px;
  padding-left: 10px;
}

.list_h4__fRPnh {
  padding: 5px;
  padding-left: 10px;
}

.list_list__EluZw {
  display: grid;
  grid-template-columns: var(--icon-size) auto;
  margin: 8px;
  padding: 10px;
  background-color: var(--secondary-color);
  border: var(--border-size) var(--secondary-color) outset;
  border-radius: 12px;
  text-decoration: none;
}

.list_list__EluZw:hover {
  border: var(--border-size) var(--secondary-color) inset;
}

.list_list__EluZw > * {
  text-decoration: none;
}

.list_listText__2AIN4 {
  color: var(--text-color);
  padding-left: 15px;
  font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/app/view/list/list.module.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,oCAAoC;EACpC,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,8EAA8E;EAC9E,aAAa;AACf;;AAEA;EACE,4CAA4C;EAC5C,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,4CAA4C;EAC5C,WAAW;EACX,aAAa;EACb,wCAAwC;EACxC,wDAAwD;EACxD,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".header,\n.menulist {\n  display: grid;\n  grid-template-rows: var(--icon-size);\n  grid-gap: var(--grid-gap);\n  background-color: var(--secondary-color);\n}\n\n.header {\n  grid-template-columns: var(--icon-size) auto var(--icon-size) var(--icon-size);\n  padding: 10px;\n}\n\n.menulist {\n  grid-template-columns: auto var(--icon-size);\n  padding: 0 10px 5px;\n}\n\n.h3 {\n  padding-bottom: 5px;\n  padding-left: 10px;\n}\n\n.h4 {\n  padding: 5px;\n  padding-left: 10px;\n}\n\n.list {\n  display: grid;\n  grid-template-columns: var(--icon-size) auto;\n  margin: 8px;\n  padding: 10px;\n  background-color: var(--secondary-color);\n  border: var(--border-size) var(--secondary-color) outset;\n  border-radius: 12px;\n  text-decoration: none;\n}\n\n.list:hover {\n  border: var(--border-size) var(--secondary-color) inset;\n}\n\n.list > * {\n  text-decoration: none;\n}\n\n.listText {\n  color: var(--text-color);\n  padding-left: 15px;\n  font-size: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `list_header__cnuNx`,
	"menulist": `list_menulist__MY-U-`,
	"h3": `list_h3__gE6Hv`,
	"h4": `list_h4__fRPnh`,
	"list": `list_list__EluZw`,
	"listText": `list_listText__2AIN4`
};
export default ___CSS_LOADER_EXPORT___;
