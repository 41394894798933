import {FC, ReactElement} from "react";

import style from "./icons.module.css";

interface props {
  date: Date | null;
}

export const Calendar: FC<props> = (props) : ReactElement => {

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');

    return day + '.' + month + '.';
  }

  const weekdays = (date: Date) => {
    var daysNumber = date.getDay();
    var weekday = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

    return weekday[daysNumber];
  }

  return (
    <div className={`${style.icon} ${style.calendar}`}>
      <div className={style.calendarUp}>{props.date ? `${formatDate(props.date)}` : ""}</div>
      <div className={style.calendarDown}>{props.date ? `${weekdays(props.date)}` : ""}</div>
    </div>
  )
}