import {FC, ReactElement, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";

import {ButtonWidth1Icon} from "../../../components/button/ButtonWidth1Icon";
import {Project} from "../../../icons/Project";
import {DatePickerModal} from "../../../components/datepicker/DatePickerModal";
import {Calendar} from "../../../icons/Calendar";
import {ProjectModal} from "./ProjectModal";

import 'react-datepicker/dist/react-datepicker.css';
import style from "../list.module.css";

interface project {
  uuid: string;
  name: string;
  customer: {
    name: string;
  }
}

export const NewProjectList: FC = (): ReactElement => {

  const api = useSelector((state: any) => state.api);
  const url = api + "/projectlist";

  const [modalProject, setModalProject] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<project | undefined>(undefined);
  const [modalDate, setModalDate] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateString, setDateString] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedDate !== null) {
      const newDate = new Date(selectedDate);

      const day: string = ('0' + newDate.getDate()).slice(-2);
      const month: string = ('0' + (newDate.getMonth() + 1)).slice(-2);
      const year: number = newDate.getFullYear();

      setDateString(`${day}.${month}.${year}`);
    }
  }, [selectedDate])

  const saveData = async () => {
    const response = await axios.post(url + "/new", {
      projectUuid: selectedProject?.uuid,
      name: selectedProject?.name,
      date: selectedDate,
      finished: false
    });
    if (response.status === 200) {
      console.log("Projekt erfolgreich gespeichert.");
    } else {
      console.error("Es gab ein Problem beim Speichern des Projekts.");
    }
    handleCancel();
  }

  const newDate = () => {
    if (selectedDate) {
      return new Date(selectedDate);
    } else {
      return null;
    }
  }

  const handleCancel = () => {
    navigate("/list");
  }

  return (
    <div className="parent">
      <h3 className={style.h3}>Neue Projektliste anlegen</h3>
      <div>

        <div onClick={() => setModalProject(true)}>
          {selectedProject === undefined
            ?
            <ButtonWidth1Icon
              item={{uuid: "1", line1: "Projekt", line2: "auswählen"}}
              icon={<Project/>}
            />
            :
            <ButtonWidth1Icon
              item={{uuid: "1", line1: selectedProject.customer.name, line2: selectedProject.name}}
              icon={<Project/>}
            />
          }
        </div>
        <ProjectModal
          selectedProject={selectedProject} setSelectedProject={setSelectedProject}
          modal={modalProject} setModal={setModalProject}
        />

        <div onClick={() => setModalDate(true)}>
          {selectedDate === null
            ?
            <ButtonWidth1Icon
              item={{uuid: "2", line1: "Datum", line2: "auswählen"}}
              icon={<Calendar date={null}/>}
            />
            :
            <ButtonWidth1Icon
              item={{uuid: "2", line1: dateString}}
              icon={<Calendar date={new Date(selectedDate)}/>}
            />
          }
        </div>
        <DatePickerModal
          date={newDate()}
          setDate={setSelectedDate}
          modal={modalDate}
          setModal={setModalDate}/>

        {selectedProject && selectedDate
          ?
          <div>
            <button onClick={handleCancel}>Abbrechen</button>
            <button onClick={saveData}>Speichern</button>
          </div>
          :
          <button onClick={handleCancel}>Abbrechen</button>
        }
      </div>
    </div>
  );
}
