import {FC, ReactElement} from "react";

import style from "./components.module.css";

interface props {
  headline: string;
}

export const Headline: FC<props> = (props) : ReactElement => {

  return (
      <h2 className={style.headline}>{props.headline}</h2>
  )

}
