import {FC, ReactElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";

import {Calendar} from "../../../icons/Calendar";

import style from "../list.module.css";

interface DataType {
  uuid: String;
  date: String;
  project: {
    name: String;
    customer: {
      name: String;
    };
  };
}

export const ProjectList: FC = (): ReactElement => {

  const api = useSelector((state: any) => state.api);
  const url = api + "/projectlist";

  const [data, setData] = useState<DataType[]>([]);
  const [weekRanges, setWeekRanges] = useState<string[]>([]);
  const [dataByWeek, setDataByWeek] = useState<any[][]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url + "/getunfinished");
        setData(response.data);
      } catch (error) {
        console.error('Es gab ein Problem beim Abrufen der Daten:', error);
      }
    }
    fetchData();
  }, [url]);

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return day + '.' + month + '.' + year;
  }

  const getWeekRange = (date: Date) => {
    const weekStart = new Date(date.getTime());
    weekStart.setDate((date.getDate() - date.getDay()) + 1);

    const weekEnd = new Date(weekStart.getTime());
    weekEnd.setDate(weekStart.getDate() + 6);

    return [weekStart, weekEnd];
  }

  useEffect(() => {
    const newWeekRanges = data.map((item) => {
      const date = new Date(item.date.toString());
      const [weekStart, weekEnd] = getWeekRange(date);
      return formatDate(weekStart) + " - " + formatDate(weekEnd);
    });

    // @ts-ignore
    const uniqueWeekRanges = [...new Set(newWeekRanges)];

    setWeekRanges(uniqueWeekRanges);

    const newDataByWeek = uniqueWeekRanges.map((weekRange) => {
      return data.filter((item) => {
        const date = new Date(item.date.toString());
        const [weekStart, weekEnd] = getWeekRange(date);
        const currentWeekRange = formatDate(weekStart) + " - " + formatDate(weekEnd);
        return currentWeekRange === weekRange;
      });
    });

    setDataByWeek(newDataByWeek);
  }, [data]);

  return (
    <div>
      <h3 className={style.h3}>Projekt Listen</h3>
      {dataByWeek.map((itemsByWeek, index) => (
        <div key={index}>
          <h4 className={style.h4}>Woche: {weekRanges[index]}</h4>
          {itemsByWeek.map((item) => (
            <div key={item.uuid} className={style.list}>
              <Calendar date={new Date(item.date)}/>
              <div className={style.listText}>
                <div>{item.project.customer.name}</div>
                <div>{item.project.name}</div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )

}