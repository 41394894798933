import {FC, ReactElement} from "react";

import {Tool} from "../../icons/Tool";

import style from './tool.module.css';

interface props {
  uuid: string;
  name: string;
  designation?: string;
  description?: string;
  comment?: string;
  producer?: {
    name: string;
  };
  nickname?: {
    name: string;
  };
  toolgroup?: {
    name: string;
  };
  storage?: {};
}

export const ToolSelected: FC<props> = (props): ReactElement => {

  return (
    <div key={props.uuid} className={style.toolselected}>
      <div>
        <Tool/>
        <div>
          <div>{props.producer?.name}</div>
          <div>{props.name}</div>
          <div>{props.nickname?.name}</div>
          <div>{props.toolgroup?.name}</div>
        </div>
      </div>
      <div>
        {props.storage
        ?
          <div>
            <div>Lager:</div>
            <div></div>
          </div>
          :
          ""
        }
        {props.description
        ?
          <div>
            <div>Bezeichnung:</div>
            <div>{props.designation}</div>
          </div>
          :
          ""
        }
        {props.description
          ?
          <div>
            <div>Beschreibung:</div>
            <div>{props.description}</div>
          </div>
          :
          ""
        }
        {props.comment
          ?
          <div>
            <div>Kommentar:</div>
            <div>{props.comment}</div>
          </div>
          :
          ""
        }
      </div>
    </div>
  )

}
